import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import { Image } from 'common/components/Image';
import { useOnScreen } from 'common/hooks/useOnScreen';
import dynamic from 'next/dynamic';
import { useState } from 'react';

export interface CalloutGridProps {
  items: CalloutGridItemProps[];
}

const Icons = {
  Add: dynamic(() => import('@mui/icons-material/Add')),
  Check: dynamic(() => import('@mui/icons-material/Check'))
};

const CalloutGrid = ({ items }: CalloutGridProps) => {
  return (
    <Box
      width={'100%'}
      alignItems={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      {items.map((item, i) => {
        return <CalloutGridItem key={i} flip={i % 2 === 0} {...item} />;
      })}
    </Box>
  );
};

CalloutGrid.strapiComponent = 'maastr.callout-grid';

export interface CalloutGridItemProps {
  image: string;
  backgroundImage: string;
  title: string;
  description: string;
  listItems: CalloutGridItemListItemProps[];
  flip?: boolean;
}

const CalloutGridItem = (props: CalloutGridItemProps) => {
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const isOnscreen = useOnScreen(ref, 300);
  const { backgroundImage, image, title, description, listItems, flip } = props;

  return (
    <Box width={1200} maxWidth={'100%'} marginBottom={7}>
      <Grid
        container={true}
        spacing={7}
        alignItems={'center'}
        direction={!flip ? 'row-reverse' : 'row'}
      >
        <Grid item={true} xs={12} md={6}>
          <Box
            ref={setRef}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              height: 520,
              maxHeight: '100vw',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              ...(isOnscreen
                ? theme.mixins.makeBackground(backgroundImage)
                : {}),
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }}
          >
            {image && (
              <Image
                src={image}
                alt={title}
                layout={'fill'}
                objectFit={'contain'}
                objectPosition={'center'}
              />
            )}
          </Box>
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Typography variant={'h3'}>{title}</Typography>
            <Box marginTop={3} marginBottom={1}>
              <Typography variant={'body1'}>{description}</Typography>
            </Box>
            <List dense={true}>
              {(listItems || []).map((listItem, i) => {
                return <CalloutGridItemListItem key={i} {...listItem} />;
              })}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

CalloutGridItem.strapiComponent = 'maastr.callout-grid-item';

export interface CalloutGridItemListItemProps {
  icon: keyof typeof Icons;
  primary: string;
  secondary: string;
}

const CalloutGridItemListItem = ({
  icon,
  primary,
  secondary
}: CalloutGridItemListItemProps) => {
  const Icon = Icons?.[icon];
  return (
    <ListItem>
      {Icon && (
        <ListItemIcon>
          <Icon fontSize={'large'} color={'primary'} />
        </ListItemIcon>
      )}
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
};

CalloutGridItemListItem.strapiComponent = 'maastr.callout-grid-item-list-item';

export { CalloutGrid, CalloutGridItem, CalloutGridItemListItem };
